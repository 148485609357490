$primary: #98b0d1;
$secondary: #606060;
$success: #44a728;
$dark: desaturate(darken($primary, 60%), 90%);
$light: #f2f2f4;

$theme-colors: (
        primary: $primary,
        secondary: $secondary,
        success: $success,
        dark: $dark,
        light: $light,
);

$font-family-base: 'Tajawal', sans-serif;
$body-color: #000;
//$font-size-base: 16;

@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
@import "../node_modules/swiper/swiper-bundle.css";

$vh-sizes: (100, 75, 50, 25, 60);

@each $s in $vh-sizes {
  @each $b, $v in $grid-breakpoints {
    @include media-breakpoint-up($b) {
      .min-vh-#{$b}-#{$s} {
        min-height: 1vh * $s !important;
      }
    }
  }
  .min-vh-#{$s} {
    min-height: 1vh * $s;
  }
}

.brands-images {
  & > .swiper-wrapper > .swiper-slide:not(.swiper-slide-active) {
    opacity: 0 !important;
  }
}

.brand-gallery {
  --swiper-theme-color: #000;
}

.black-stroke-font {
  text-shadow: -1px 1px 0 $secondary,
  1px 1px 0 $secondary,
  1px -1px 0 $secondary,
  -1px -1px 0 $secondary;
  //color: white;
}

.has-fill-parent {
  position: relative;

  > .fill-parent, .fill-parent-bg {
    position: relative;
    height: 100%;
    width: 100%;
  }

  > .fill-parent-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    & ~ * {
      position: relative;
      z-index: 1;
    }
  }
}

#navbar {
  transition: background-color 0.2s ease-out, transform 0.2s ease-out, box-shadow 0.2s ease-out;

  &.hide {
    transform: translateY(-100%);
    @extend .shadow-none;
  }

  &.top {
    @extend .bg-transparent;
    filter: contrast(0) brightness(300%);
  }
}

.header-bg-image {
  background-size: cover;
  background-position: center center;
  //background-image: url(https://images.unsplash.com/photo-1449247709967-d4461a6a6103?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1502&q=80), linear-gradient(#f8f8f8 0%, #f2f2f4 90%);
  background-image:
  linear-gradient(rgba($primary, .4), rgba($primary, .4)),
          url(https://images.unsplash.com/photo-1605371924599-2d0365da1ae0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80);
  background-repeat: no-repeat;
  filter: saturate(.7) brightness(0.7);
}

.services-bg-pattern {
  // https://www.svgbackgrounds.com/#bullseye-gradient
  //background-color: #ffbf00;
  //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 800'%3E%3Cg fill-opacity='0.15'%3E%3Ccircle fill='%23ffbf00' cx='400' cy='400' r='600'/%3E%3Ccircle fill='%23e0ab2f' cx='400' cy='400' r='500'/%3E%3Ccircle fill='%23c29842' cx='400' cy='400' r='400'/%3E%3Ccircle fill='%23a3864f' cx='400' cy='400' r='300'/%3E%3Ccircle fill='%2384735a' cx='400' cy='400' r='200'/%3E%3Ccircle fill='%23626262' cx='400' cy='400' r='100'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  background-position: center center;
  height: 200% !important;
}

.phone .notch-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100px;
}

.phone .notch-container .notch {
  width: 40%;
  height: 20%;
  margin: 0 auto;
  background-color: $secondary;
  border-bottom-left-radius: 2.334vh;
  border-bottom-right-radius: 2.334vh;
}

.phone {
  position: relative;
}

.phone {
  display: block;
  border: 1.334vh solid $secondary;
  border-radius: 4.5vh;
}
